<template>
  <auth-content>
    <el-header slot="header"></el-header>
    <form @submit.prevent="PreferencAnnuaire" v-if="preferencAnnuaireModule">
      <div class="card card-content">
        <div class="card-body">

            <div class="row row-section" v-for="(item, key) in preferencAnnuaireModule" :key="key"  >
              <div class="col-md-12">
                <button type="button" class="mb-0 type-list" aria-expanded="true" data-toggle="collapse" :data-target="'#'+item.category.replace(/[^a-zA-Z0-9]/g, '-')">
                  <i class="icon-arrow-down12"></i>
                  {{ item.category }}
                </button>
              </div>
              <div :id="item.category.replace(/[^a-zA-Z0-9]/g, '-')" class="row-items collapse show">
                <div class="col-md-12">
                  <p>{{ item.description }}</p>
                </div>
                <div class="col-md-3" v-for="(permission, index) in item.list" :key="index">
                  <div class="cursor-pointer" @click="togglePermission(key, index)">
                    <i v-if="permission.value" class="icon-checkbox-checked2 mr-1 text-primary"></i>
                    <i v-if="!permission.value" class="icon-checkbox-unchecked2 mr-1 text-grey"></i>
                    <label class="mt-2 mb-1 font-weight-bold">{{ permission.name }}</label>
                  </div>
                </div>
              </div>
            </div>

          </div>
          <div class="card-footer">
            <button v-if="!loadingPreferencAnnuaire" class="btn btn-lg btn-primary text-white">{{ $t(module+'.partenariat.form.submit') }}</button>
            <div v-if="loadingPreferencAnnuaire" class="bg-white p-2">
              <span class="text-primary"><loader></loader></span>
            </div>
          </div>

        </div>
    </form>
  </auth-content>
</template>

<script>

import store from '@/store'
import {
PARTNERSHIP_PREFERENCE_ANNUAIRE,
PARTNERSHIP_PREFERENCE_ANNUAIRE_STORE
} from '../mutations-types'
export default {
  name: 'PreferencAnnuaire',
  data () {
    return {
      module: 'modules.partnership',
      loadingPreferencAnnuaire: false,
      preferencAnnuaireModule: false,
      preferencAnnuaireData: false
    }
  },
  beforeRouteEnter (to, form, next) {
    store.dispatch('partnership/' + PARTNERSHIP_PREFERENCE_ANNUAIRE, store.getters['auth/getWorld'].id).then(permissions => {
      next(vm => {
        vm.preferencAnnuaireModule = permissions[0]
        vm.preferencAnnuaireData = permissions[1]
        vm.preferencAnnuaireData.forEach(item => {
          vm.preferencAnnuaireModule.forEach((module, index) => {
            if(module.category == item.category) {
              module.list.forEach(list => {
                if(list.name == item.item) {
                  list.value = true
                }
              })
            }
          })
        })
      })
    })
  },
  methods: {
    /**
    * @vuese
    * Store data preferance annaire
    */
    PreferencAnnuaire () {
      const data = []
      this.preferencAnnuaireModule.forEach(category => {
        category.list.forEach(permission => {
          if(permission.value == true) {
            Object.assign(permission,{category: category.category});
            data.push(permission)
          }
        })
      })
      this.loadingPreferencAnnuaire = true
      this.$store.dispatch('partnership/' + PARTNERSHIP_PREFERENCE_ANNUAIRE_STORE,
      {worldId: this.$store.getters['auth/getWorld'].id, data: data})
      .then(data => {
        this.loadingPreferencAnnuaire = false
        this.notifSuccess(data)
      })
    },
    togglePermission(key, index) {
      this.preferencAnnuaireModule[key].list[index].value = this.preferencAnnuaireModule[key].list[index].value ? false : true;
    }
  }
}
</script>
