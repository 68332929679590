<!-- Page Dashboard for Partner  -->
<template>
  <auth-content>
    <el-header slot="header">
    </el-header>
    <div class="card card-content live__scroll">
      <div class="card-body">
        <div class="row row-menu ">
          <div class="col-4 item">
            <h2>{{ $t(module+'.partenariat.dash.contenu') }}</h2>
            <p>{{ $t(module+'.partenariat.dash.contenu_1') }}</p>
          </div>
          <div class="col-4 item">
          </div>
          <div class="col-1 item text-center">
            <span :class="[worldNiveau == 1 ? 'silver icon-medal-star isActive': 'silver icon-medal-star']"></span>
            {{ $t(module+'.partenariat.dash.SILVER') }}
          </div>
          <div class="col-1 item text-center">
            <span :class="[worldNiveau == 2 ? 'gold icon-medal-star isActive': 'gold icon-medal-star']"></span>
            {{ $t(module+'.partenariat.dash.OR') }}
          </div>
          <div class="col-1 item text-center">
            <span :class="[worldNiveau == 3 ? 'platine icon-medal-star isActive':'platine icon-medal-star']"></span>
            {{ $t(module+'.partenariat.dash.PLATINE') }}
          </div>
          <div class="col-1 item text-center">
            <span :class="[worldNiveau == 4 ? 'diamant icon-medal-star isActive':'diamant icon-medal-star']"></span>
            {{ $t(module+'.partenariat.dash.DIAMANT') }}
          </div>
        </div>
        <div class="row row-getion">
          <div class="col-4 item">
            <p>{{ $t(module+'.partenariat.dash.contenu_2') }}</p>
          </div>
          <div class="col-4 item">
            {{ $t(module+'.partenariat.dash.contenu_4', {count: mrrGesionCount }) }}
          </div>
          <div class="col-1 item" v-for="item in mrrProgress[0].MRR_VENTE">
            {{item , global.symbol | filterCurrencie}}
          </div>
          <div class="row-progress">
            <div class="col price"> {{MRR_VENTE.toLocaleString() , global.symbol | filterCurrencie}} </div>
            <div class="col text">
              <span class="description">{{ $t(module+'.partenariat.dash.contenu_6') }}</span>
            </div>
            <div class="progress">
              <div class="progress-bar" role="progressbar" :style="'width:'+progressbar(mrrProgress[0].MRR_VENTE, MRR_VENTE )" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
            </div>
          </div>
        </div>
        <div class="row row-getion">
          <div class="col-4 item">
            <p>{{ $t(module+'.partenariat.dash.contenu_3') }}
              <span class="certificate" v-if="worldCertification != null"> :  <b>{{ certification[worldCertification]  }}</b> </span>
              <span class="certificate diamant icon-cross2 text-danger" v-else="worldCertification != null"></span>
            </p>
          </div>
          <div class="col-4 item">
            {{ $t(module+'.partenariat.dash.contenu_5', {count: mrrVenteCount}) }}
          </div>
          <div class="col-1 item" v-for="item in mrrProgress[1].MRR_GESTION">
            {{item , global.symbol | filterCurrencie}}
          </div>
          <div class="row-progress">
            <div class="col price">{{MRR_GESTION.toLocaleString() , global.symbol | filterCurrencie}} </div>
            <div class="col text">
              <span class="price-description">MRR-gestions</span>
            </div>
            <div class="progress">
              <div class="progress-bar" role="progressbar" :style="'width:'+progressbar(mrrProgress[1].MRR_GESTION, MRR_GESTION )" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <el-table
      :total="Object.keys(clients).length"
      :columns="columns"
      :data="clients"
    >
    </el-table>
  </auth-content>
</template>

<script>
import store from '@/store'
import {
WORLD_REQUEST
} from '@/store/mutations-types'
import {
PARTNERSHIP_MRR_REQUEST,
CURRENCIE_DATA
} from '../mutations-types'
import moment from 'moment'
export default {
  name: 'PreferencAannuaire',
  data () {
    return {
      module: 'modules.partnership',
      mrrProgress: [
        {MRR_VENTE: []},
        {MRR_GESTION: []}
      ],
      MRR_VENTE: 0,
      MRR_GESTION: 0,
      worldNiveau: store.getters['auth/getWorld'].niveau,
      worldCertification: store.getters['auth/getWorld'].certification,
      certification: {},
      global: {},
      mrrGesionCount: 0,
      mrrVenteCount: 0,
      columns: {
        company: this.$t('modules.partnership.partenariat.dash.table.company'),
        mrr: this.$t('modules.partnership.partenariat.dash.table.mrr'),
        mode_payant: this.$t('modules.partnership.partenariat.dash.table.mode_payant'),
        forfait: this.$t('modules.partnership.partenariat.dash.table.forfait'),
        date_activation_partnership: this.$t('modules.partnership.partenariat.dash.table.date_invitation')
      },
      clients: {}
    }
  },
  filters: {
    /**
    * @vuese
    *  get currencie world
    */
    filterCurrencie: function(value, current) {
      if(current == "€") {
        return current +' ' +value
      }
      return value +' ' +current
    }
  },
  beforeRouteEnter (to, from, next) {
    store.dispatch('partnership/' + PARTNERSHIP_MRR_REQUEST, store.getters['auth/getWorld'].id).then(data => {
      store.dispatch('partnership/' + CURRENCIE_DATA, store.getters['auth/getWorld'].id).then(global => {
        next(vm => {
           vm.global = {...global}
           vm.mrrProgress = data[0]
           vm.MRR_VENTE = store.getters['auth/getWorld'].mrr_vente
           vm.MRR_GESTION = store.getters['auth/getWorld'].mrr_gestion
           vm.certification = data[1]
           vm.mrrGesionCount = data[3].count_clients
           vm.mrrVenteCount = data[3].count_clients_month
           data[2].map(item => {
             if(global.symbol == "€") {
               item.mrr = global.symbol +' ' + item.mrr
             } else {
               item.mrr = item.mrr +' ' + global.symbol
             }
            if(item.mode_payant == "paid") {
              item.mode_payant = vm.$t(vm.module+'.partenariat.dash.table.Vendu')
            } else {
              item.mode_payant = vm.$t(vm.module+'.partenariat.dash.table.Non_vendu')
            }
            item.date_activation_partnership = moment(item.date_activation_partnership, 'YYYY-MM-DD').format('DD/MM/YYYY');
           })
           vm.clients = data[2]
        })
      })
    })
  },
  methods: {
    /**
    * @vuese
    *  this function controlle bar progress
    */
    progressbar (dataProgress = [], mrr) {
      mrr = parseFloat(mrr);
      var progress = 0, min_mrr = 0
      const prencetage = [50.33, 63, 75.9, 88.8, 100]
      var min_perct = 0
      for(var i = 0 ; i < dataProgress.length; i++) {
        if(mrr < dataProgress[i]) {
          if(i > 0) {
            min_perct =  prencetage[i-1]
            min_mrr = dataProgress[i-1]
          }

          var max_perct = prencetage[i]
          var max_mrr = dataProgress[i]
          var avg = 0
          if(mrr > min_mrr){
            avg = ((mrr-min_mrr)/(max_mrr - min_mrr))*100
          }

          progress = min_perct + (avg * (max_perct - min_perct) / 100)
          return `${progress}%`
        }
      }
      return `100%`
    }
  }
}
</script>

<style lang="scss">
.row-section {
  margin-bottom: 50px;
  border-bottom: 1px solid #b5b5b561;
  padding-bottom: 25px;
  .row-items {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    padding-left: 28px;
    &.collapse {
      display: none;
      &.show {
        display: flex;
      }
    }
  }
  .type-list{
    background: #fff0;
    border: none;
    font-size: 22px;
    font-weight: 500;
    i{
      color: #17bed0
    }
  }
}
.row-getion, .row-menu{
  h2, p{
    text-align: center;
    width: 215px;
  }
  p{
    font-size: 17px;
  }
}
.row-menu{
  height: 200px;
  .item{
    text-align: center;
    .img-thumbnail{
      border: none;
      background: none;
    }
  }
  span{
    font-size: 46px;
    display: block;
    margin-bottom: 20px;
    &.silver {
      color:  #c0c0c057;
      &.isActive {
        color:  #C0C0C0;
      }
    }
    &.gold{
      color: #ffd70040;
      &.isActive {
        color: #FFD700;
      }
    }
    &.platine{
      color: #e5e4e280;
      &.isActive {
        color: #e5e4e2;
      }
    }
    &.diamant {
      color: #c5ddf959;
      &.isActive {
        color: #c5ddf9;
      }
    }
  }
}
.row-getion{
  position: relative;
  height: 120px;
  p{
    text-align: left;
  }
}
.row-progress {
  width: 65%;
  margin-left: 34%;
  position: absolute;
  top: -5pc;
  .price{
    font-size: 22px;
    font-weight: 500;
    padding-left: 0;
  }
  .text{
    padding-left: 0;
    .info{
      float: right;
    }
  }
}
.item {
  border-right: 1px solid #33333329;
}

.progress-bar {
  animation: progress 1.5s ease-in-out forwards;
}

@keyframes progress {
  from {
    width: 0;
  }
}

@media screen and (max-width: 992px) {
  .live__scroll {
    min-width: 900px;
    overflow-x: scroll;
  }
}
.certificate {
  float: right;
}

</style>
