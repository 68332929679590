<template>
  <auth-content>
    <el-header slot="header"></el-header>
    <form @submit.prevent="profile">
      <div class="card card-content">
        <div class="card-header">
          <h5 class="mb-0">{{ $t(module+'.partenariat.form.title') }}</h5>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-md-6">

              <div class="file-upload-form">
                <label class="mt-2 mb-1 font-weight-bold">
                  {{ $t(module+'.partenariat.form.picture') }}
                </label>
                <input ref="pictureInput" class="d-none" type="file" @change="previewPicture" accept="image/gif, image/jpeg, image/png" />
                <div v-if="user.logo">
                  <el-avatar v-if="user.logo.base64" :src="user.logo.base64"></el-avatar>
                  <el-avatar v-if="user.logo.thumb" :src="user.logo.thumb"></el-avatar>
                  <div @click="changePicture()" class="ml-2 btn btn-sm btn-light">{{ $t(module+'.partenariat.form.picture_change') }}</div>
                </div>
                <div>
                  <div @click="changePicture()" class="btn btn-sm btn-light" v-if="!user.logo">{{ $t(module+'.partenariat.form.picture_add') }}</div>
                </div>
              </div>

              <label class="mt-2 mb-1 font-weight-bold label-required">{{ $t(module+'.partenariat.form.nom') }}</label>
              <input v-model="user.nom" class="form-control" required type="text" minlength="2" maxlength="150" :placeholder="$t(module+'.partenariat.form.nom')"/>

              <label class="mt-2 mb-1 font-weight-bold">{{ $t(module+'.partenariat.form.site_web') }}</label>
              <input v-model="user.site_web" class="form-control" type="url" :placeholder="$t(module+'.partenariat.form.site_web')"/>

              <label class="mt-2 mb-1 font-weight-bold">{{ $t(module+'.partenariat.form.localisation') }}</label>
              <places
                :options="{
                  type:'address',
                  templates: {
                    value: function(suggestion) {
                      return suggestion.name;
                    }
                  }}"
                v-model="user.localisation"
                class="form-control"
                :placeholder="$t(module+'.partenariat.form.localisation')"
              >
              </places>

              <label class="mt-2 mb-1 font-weight-bold">{{ $t(module+'.partenariat.form.description') }}</label>
              <textarea v-model="user.description" class="form-control" :placeholder="$t(module+'.partenariat.form.description')"/>

              <label class="mt-2 mb-1 font-weight-bold">{{ $t(module+'.partenariat.form.rib_iban') }}</label>
              <input v-model="user.rib_iban" minlength="3" maxlength="50" class="form-control" type="text" :placeholder="$t(module+'.partenariat.form.rib_iban')"/>

              <label class="mt-2 mb-1 font-weight-bold">{{ $t(module+'.partenariat.form.code_banque') }}</label>
              <input v-model="user.code_banque" minlength="3" maxlength="50" class="form-control" type="text" :placeholder="$t(module+'.partenariat.form.code_banque')"/>

              <div class="cursor-pointer" @click="togglePermission()">
                <i v-if="profil_public" class="icon-checkbox-checked2 mr-1 text-primary"></i>
                <i v-if="!profil_public" class="icon-checkbox-unchecked2 mr-1 text-grey"></i>
                <label class="mt-2 mb-1 font-weight-bold">{{ $t(module+'.partenariat.form.profil_public') }}</label>
              </div>

              </div>
            </div>
          </div>

          <div class="card-footer">
            <button v-if="!loadingProfile" class="btn btn-lg btn-primary text-white">{{ $t(module+'.partenariat.form.submit') }}</button>
            <div v-if="loadingProfile" class="bg-white p-2">
              <span class="text-primary"><loader></loader></span>
            </div>
          </div>

        </div>
      </div>
    </form>
  </auth-content>
</template>

<script>

import Places from 'vue-places'
import store from '@/store'
import {
PARTNERSHIP_PROFILE,
PARTNERSHIP_PROFILE_REQUEST
} from '../mutations-types'

export default {
  name: 'ProfilePartnerForm',
  components: {
    Places
  },
  data () {
    return {
      module: 'modules.partnership',
      loadingProfile: false,
      user: {},
      profil_public: false
    }
  },
  beforeCreate (to, from, next) {
    store.dispatch('partnership/' + PARTNERSHIP_PROFILE_REQUEST, this.$route.params.world).then(resp => {
      typeof resp != 'undefined'? this.user = resp : this.user = {};
      if(typeof this.user.profil_public != 'undefined')
        this.user.profil_public == 0 ? this.profil_public = false : this.profil_public = true
    })
  },
  methods: {
    /**
    * @vuese
    *  Store data profile
    */
    profile () {
      this.user.profil_public = this.profil_public

      if(((!!this.user.logo && !!this.user.logo.base64) || this.user.nom != null || this.user.site_web != null ||this.user.localisation != null ||this.user.description != null ||this.user.rib_iban != null ||this.user.code_banque != null || this.user.profil_public!= null ) && this.$route.params.world) {
        const worldId = this.$route.params.world
        this.$store.dispatch('partnership/' + PARTNERSHIP_PROFILE, {profile: this.user, worldId: worldId}).then((resp)=> {
          this.notifSuccess(resp)
        })
      }
    },
    async previewPicture (event) {
      // Reference to the DOM input element
      var input = event.target
      // Ensure that you have a file before attempting to read it
      if (input.files && input.files[0]) {
        // create a new FileReader to read this image and convert to base64 format
        var reader = new FileReader()
        // Define a callback function to run, when FileReader finishes its job
        reader.onload = (e) => {
          // Note: arrow function used here, so that "this.imageData" refers to the imageData of Vue component
          // Read image as base64 and set to imageData
          this.user.logo = {}
          this.user.logo.base64 = e.target.result
        }
        // Start the reader job - read file as a data url (base64 format)
        reader.readAsDataURL(input.files[0])
      }
    },
    /**
    * @vuese
    * change picher profile
    */
    changePicture () {
      this.user.logo = null
      this.$refs.pictureInput.click()
    },
    /**
    * @vuese
    *  change value profile public or not
    */
    togglePermission () {
      this.profil_public = !this.profil_public
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
