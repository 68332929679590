<!-- Page for (Gestion Prospect) -->
<template>
  <auth-content>
    <el-header slot="header">
      <el-header-action v-if="true" size="xs" slot="actions" icon="plus3" :label="$t(module+'.partenariat.prospect_management.invite')" @click.native="openModal('prospect-management-invite')"></el-header-action>
    </el-header>
      <!-- Table for client added in this world   -->
      <el-table
        :total="Object.keys(clients).length"
        :columns="columns"
        :data="clients"
      >

      <div slot="actions" slot-scope="{row}" v-if="row.partnership == '0'">

        <el-table-action
          @click.prevent.native="partnership_edit=row; openModal('prospect-management-edit')"
          icon="icon-pen6"
          :title="$t(module+'.partenariat.user.list.edit_user')">
        </el-table-action>

        <el-table-action
          @click.prevent.native="partnership_edit=row; sendPartnership(partnership_edit)"
          icon="icon-envelop"
          :title="$t(module+'.partenariat.prospect_management.email')">
        </el-table-action>

        <el-table-action
          @click.prevent.native="confirmPartnershipRemove(row.id)"
          icon="icon-bin"
          :title="$t(module+'.partenariat.user.list.remove_user')">
        </el-table-action>
      </div>

      </el-table>

    </div>

    <!-- Modal for add new Client -->
    <el-modal size="sm" :title="$t(module+'.partenariat.prospect_management.invite')" id="prospect-management-invite">
      <form @submit.prevent="addPartnership()">
        <div class="form-group">
          <label for="first_name" class="label-required font-weight-bold">{{ $t(module+'.partenariat.form.first_name') }}</label>
          <input v-model="partnership.first_name" required type="text" class="form-control" id="first_name" :placeholder="$t(module+'.partenariat.form.first_name')">
        </div>
        <div class="form-group">
          <label for="last_name" class="label-required font-weight-bold">{{ $t(module+'.partenariat.form.last_name') }}</label>
          <input v-model="partnership.last_name" required type="text" class="form-control" id="last_name" :placeholder="$t(module+'.partenariat.form.last_name')">
        </div>
        <div class="form-group">
          <label for="entreprise" class="label-required font-weight-bold">{{ $t(module+'.partenariat.form.entreprise') }}</label>
          <input v-model="partnership.company" required type="text" class="form-control" id="company" :placeholder="$t(module+'.partenariat.form.entreprise')">
        </div>
        <div class="form-group">
          <label for="email" class="label-required font-weight-bold">{{ $t(module+'.partenariat.form.email') }}</label>
          <input v-model="partnership.email" required type="email" class="form-control" id="email" :placeholder="$t(module+'.partenariat.form.email')">
        </div>
        <div class="text-center">
          <button v-if="!partnership_loading" type="submit" class="btn btn-primary text-white">{{ $t(module+'.partenariat.form.save') }}</button>
          <div v-if="partnership_loading" class="text-primary pt-1 pb-2"><loader></loader></div>
        </div>
      </form>
    </el-modal>
    <!-- End Modal add Client -->

    <!-- Modal for modifier Client by  -->
    <el-modal size="sm" :title="$t(module+'.partenariat.prospect_management.edit.name')" id="prospect-management-edit">
      <form @submit.prevent="editPartnership()">
        <div class="form-group">
          <label for="first_name" class="label-required font-weight-bold">{{ $t(module+'.partenariat.form.first_name') }}</label>
          <input v-model="partnership_edit.first_name" required type="text" class="form-control" id="first_name" :placeholder="$t(module+'.partenariat.form.first_name')">
        </div>
        <div class="form-group">
          <label for="last_name" class="label-required font-weight-bold">{{ $t(module+'.partenariat.form.last_name') }}</label>
          <input v-model="partnership_edit.last_name" required type="text" class="form-control" id="last_name" :placeholder="$t(module+'.partenariat.form.last_name')">
        </div>
        <div class="form-group">
          <label for="entreprise" class="label-required font-weight-bold">{{ $t(module+'.partenariat.form.entreprise') }}</label>
          <input v-model="partnership_edit.company" required type="text" class="form-control" id="company" :placeholder="$t(module+'.partenariat.form.entreprise')">
        </div>
        <div class="form-group">
          <label for="email" class="label-required font-weight-bold">{{ $t(module+'.partenariat.form.email') }}</label>
          <input v-model="partnership_edit.email" required type="email" class="form-control" id="email" :placeholder="$t(module+'.partenariat.form.email')">
        </div>
        <div class="text-center">
          <button v-if="!partnership_loading" type="submit" class="btn btn-primary text-white">{{ $t(module+'.partenariat.form.save') }}</button>
          <div v-if="partnership_loading" class="text-primary pt-1 pb-2"><loader></loader></div>
        </div>
      </form>
    </el-modal>
    <!-- End Modal modifier Client -->

    <!-- Start modal remove client -->
    <el-modal size="sm" :title="$t(module+'.partenariat.prospect_management.remove_user')" id="user-partnership-remove">
      <form @submit.prevent="removePartnership()">
        <div v-if="!remove_loading" class="form-check mb-3">
          <label class="form-check-label">
            <input type="checkbox" required class="form-check-input" v-model="remove_user_confirm">
            {{ $t(module+'.partenariat.prospect_management.remove_user_confirmation_text') }}
          </label>
        </div>
        <div class="text-center">
          <button v-if="!remove_loading" type="submit" class="btn btn-danger text-white">{{ $t(module+'.partenariat.form.submit') }}</button>
          <div v-if="remove_loading" class="text-primary pt-1 pb-2"><loader></loader></div>
        </div>
      </form>
    </el-modal>
    <!-- end modal remove client  -->
  </auth-content>
</template>

<script>
import store from '@/store'
import {
PARTNERSHIP_STORE,
PARTNERSHIP_USERS_REQUEST,
PARTNERSHIP_UPDATE,
PARTNERSHIP_MAIL,
PARTNERSHIP_REMOVE
} from '../mutations-types'

export default {
  name: 'PrtenariatProspectManagement',
  data () {
    return {
      module: 'modules.partnership',
      partnership: {},
      partnership_edit: {},
      partnership_loading: false,
      remove_user_confirm: false,
      remove_user_id: null,
      remove_loading: false,
      clients: false,
      columns: {
        first_name: this.$t('modules.partnership.partenariat.form.user.first_name'),
        last_name: this.$t('modules.partnership.partenariat.form.user.last_name'),
        email: this.$t('modules.partnership.partenariat.form.user.email'),
      },
    }
  },
  beforeCreate (to, from, next) {
    store.dispatch('partnership/' + PARTNERSHIP_USERS_REQUEST, this.$store.getters['auth/getWorld']).then(clients => {
      this.clients = clients
    })
  },
  methods: {
    /**
    * @vuese
    * This function for load Partnerships
    */
    loadPartnerships () {
      this.$store.dispatch('partnership/' + PARTNERSHIP_USERS_REQUEST, {id: this.$route.params.world}).then(clients => {
        this.clients = clients
      })
    },
    /**
    * @vuese
    * This function execut for add new client
    */
    addPartnership () {
      this.partnership_loading = true
      this.partnership.partner_world_id = this.world.id
      this.partnership.code_partnership = new Date()
      this.$store.dispatch('partnership/' + PARTNERSHIP_STORE, {
        Partnership: this.partnership
      }).then(data => {
        this.partnership_loading = false
        this.loadPartnerships()
        this.notifSuccess(data)
        this.closeModal('prospect-management-invite')
        this.partnership = {}
      })
      .catch(error => {
        this.partnership_loading = false
        this.notifError(error)
      })
    },
    /**
    * @vuese
    *  This function execut for edit a client
    */
    editPartnership () {
      this.partnership_loading = true
      this.$store.dispatch('partnership/' + PARTNERSHIP_UPDATE, {
        worldId: this.world.id,
        partnership: this.partnership_edit
      }).then(data => {
        this.partnership_loading = false
        this.notifSuccess(data)
        this.closeModal('prospect-management-edit')
        this.partnership = {}
      })
      .catch(error => {
        this.partnership_loading = false
        this.notifError(error)
      })
    },
    /**
    * @vuese
    * This function execut for send mail to clinets
    */
    sendPartnership (destinationClient) {
      this.$store.dispatch('partnership/' + PARTNERSHIP_MAIL, {
        worldId: this.world.id,
        email: destinationClient.email
      }).then(data => {
        this.notifSuccess(data)
      })
    },
    /**
    * @vuese
    *  This function execut to get id client
    */
    confirmPartnershipRemove (userId) {
      this.remove_user_confirm = false
      this.remove_user_id = userId
      this.openModal('user-partnership-remove');
    },
    /**
    * @vuese
    * This function execut for remove client
    */
    removePartnership() {
      if (this.remove_user_confirm) {
        this.remove_loading = true
        this.$store.dispatch('partnership/' + PARTNERSHIP_REMOVE, {
          worldId: this.world.id,
          id: this.remove_user_id
        }).then(data => {
          this.remove_loading = false
          this.loadPartnerships()
          this.notifSuccess(data)
          this.closeModal('user-partnership-remove')
          this.remove_user_id = null
          this.remove_user_confirm = false
        })
        .catch(error => {
          this.remove_loading = false
          this.notifError(error)
        })
      }
    }
  },
  computed: {
    // get World how Partner use
    world () {
      return this.$store.getters['auth/getWorld']
    }
  }
}
</script>
