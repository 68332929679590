<template>
  <div>

    <div class="text-center">
      <div class="font-weight-bold mb-3">
        <h3 class='py-md-4'>{{ $t('user.register.title') }}</h3>
      </div>
      <form @submit.prevent="registerWithToken()" class="form row justify-content-center align-items-center">
        <div class="col-md-5 mb-3 mb-md-0">
          <img src="@/assets/images/register.svg" class="lutin mb-3">
          <div class="pr-md-2">
            <p class="font-weight-bold mb-3">{{ $t('user.register.text_left') }}</p>
            <p class="text-left"><i class="icon-check text-primary"></i> {{ $t('user.register.text_bullet_1') }}</p>
            <p class="text-left"><i class="icon-check text-primary"></i> {{ $t('user.register.text_bullet_2') }}</p>
            <p class="text-left"><i class="icon-check text-primary"></i> {{ $t('user.register.text_bullet_3') }}</p>
          </div>
        </div>
        <div class="col-md-5 text-left">
          <el-alert type="warning" v-if="formError !== false">
            <span v-html="formError"></span>
          </el-alert>
          <label class="font-weight-bold" for="email">{{ $t('user.register.email') }}</label>
          <div class="form-group form-group-feedback form-group-feedback-left">
            <input required autocomplete="email" v-model="email" type="email" id="email" class="form-control" :placeholder="$t('user.register.email')">
            <div class="form-control-feedback">
              <i class="icon-user text-muted"></i>
            </div>
          </div>
          <label class="font-weight-bold" for="email">{{ $t('user.register.company') }}</label>
          <div class="form-group form-group-feedback form-group-feedback-left">
            <input required autocomplete="company" v-model="company" type="text" id="company" class="form-control" :placeholder="$t('user.register.company')">
            <div class="form-control-feedback">
              <i class="icon-user text-muted"></i>
            </div>
          </div>
          <label class="font-weight-bold" for="email">{{ $t('user.register.password') }}</label>
          <div class="form-group form-group-feedback form-group-feedback-left">
            <input required autocomplete="current-password" v-model="password" type="password" class="form-control" :placeholder="$t('user.register.password')">
            <div class="form-control-feedback">
              <i class="icon-lock2 text-muted"></i>
            </div>
          </div>
          <div class="text-center">
            <button v-if="$store.state.auth.status != 'loading'" type="submit" class="my-2 px-3 btn btn-primary text-white btn-lg">
              <i class="icon-magic-wand mr-2"></i> {{ $t('user.register.register') }}
            </button>
            <div v-if="$store.state.auth.status == 'loading'" class="bg-white p-2">
              <span class="text-primary"><loader></loader></span>
            </div>
          </div>
        </div>
        <div class="my-2 col-12">
          <hr>
          <router-link class="font-weight-bold border-bottom" tag="a" to="/login">{{ $t('user.register.login_link') }}</router-link>
        </div>
      </form>
    </div>

  </div>
</template>

<script>

import {
  AUTH_REGISTER
} from '@/store/mutations-types'
import {
  PARTNERSHIP_VERIFY_TOKEN, PARTNERSHIP_UPDATE_CLIENT
} from '../mutations-types'

export default {
  data () {
    return {
      email: '',
      password: '',
      company: '',
      formError: false,
      data: {}
    }
  },
  mounted () {
  },
  methods: {
    /**
    * @vuese
    * register client who have token
    */

    registerWithToken: function() {
      this.setPasswordLoading = true
      this.formError = false
      const { email, password, company } = this
      this.$store.dispatch('partnership/' + PARTNERSHIP_VERIFY_TOKEN, { token: this.$route.params.token }).then(data => {
        const { first_name, last_name } = data
        this.$store.dispatch('auth/' + AUTH_REGISTER, { email, password, company }).then((resp) => {
          this.$store.dispatch('partnership/' + PARTNERSHIP_UPDATE_CLIENT, { id: resp.data.success.user.id, first_name: first_name, last_name: last_name })
          this.$router.push('/')
        })
        .catch((errors) => {
          this.formError = ''
          _.toArray(errors.response.data.errors).forEach( (element, index) => {
            this.formError+= '' + element[0] + '<br/>'
          })
        })
      })
    }
  },
  beforeCreate (to, from, next) {
    if(typeof this.$route.params.token != "undefined" && this.$route.params.token) {
      this.$store.dispatch('partnership/' + PARTNERSHIP_VERIFY_TOKEN, { token: this.$route.params.token }).then(data => {
        this.email = data.email
        this.company = data.company
        this.data = data
      })
      .catch(error => {
        this.$router.push('/register')
        if(error != false)
          this.notifError(error)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.lutin {
  max-width: 220px;
}
</style>
